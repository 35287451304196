import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import theme from '@mui/theme';
import { fabVariants } from '@mui/mui-animations';
import { AnimatePresence, motion } from 'framer-motion';
import Typography from '@mui/material/Typography';

import { FieldParagraphProps } from '@model/paragraph-props.model';
import { FabModel } from '@model/fab.model';

const Box = dynamic(() => import('@mui/material/Box'));
const Button = dynamic(() => import('@mui/material/Button'));
const IconButton = dynamic(() => import('@mui/material/IconButton'));
const Divider = dynamic(() => import('@mui/material/Divider'));
const Stack = dynamic(() => import('@mui/material/Stack'));
const AddIcon = dynamic(() => import('@mui/icons-material/Add'));
const ArrowForwardIcon = dynamic(() => import('@mui/icons-material/ArrowForward'));
const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));

const FabParagraph = (props: FieldParagraphProps) => {
  const { fieldParagraph } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const [isOpened, setIsOpened] = useState(false);

  const negate = fieldParagraph.field_negate;
  const pages = fieldParagraph.field_pages?.split('\r\n');
  const isExcludedPage = pages.includes(router.asPath);

  const data = useMemo(() => {
    const newData: FabModel = {
      title: fieldParagraph.field_title || '',
      // todo:
      link: { ...fieldParagraph.field_link },
      items: [...fieldParagraph.field_links],
    };

    return newData;
  }, [fieldParagraph]);

  const title = useMemo(() => {
    if (!data?.title) return '';

    return isOpened ? t('close') : data.title;
  }, [isOpened, data?.title, t]);

  if (!data || (negate && isExcludedPage) || (!negate && !isExcludedPage)) return null;

  return (
    <Box
      position="fixed"
      right={{ xs: isOpened ? 0 : theme.spacing(8), lg: theme.spacing(16) }}
      bottom={{ xs: isOpened ? 0 : theme.spacing(8), lg: theme.spacing(16) }}
      zIndex={1100}
      sx={{
        transition: 'border-radius 200ms ease, right: 200ms ease, bottom 200ms ease',
        flexFlow: 'column nowrap',
        backdropFilter: `blur(${theme.spacing(2)})`,
        '& a': {
          textDecoration: 'none',
        },
      }}
      width={isOpened ? { xs: '100%', lg: 'auto' } : 'auto'}
      bgcolor="alphaBlack.50"
      borderRadius={isOpened ? { xs: 0, lg: theme.spacing(32) } : theme.borderRadius.surface}
      padding={theme.spacing(16)}
      display="inline-flex"
      justifyContent="flex-end"
    >
      <AnimatePresence>
        {isOpened && (
          <motion.div initial="collapsed" animate="open" exit="collapsed" variants={fabVariants}>
            <Box padding={theme.spacing(8, 8, 22)}>
              <Stack spacing={theme.spacing(16)}>
                {data.items.map((item, index) => (
                  <LinkChildren key={index} isExternal={false} url={item.url}>
                    <Stack
                      alignItems="center"
                      justifyContent="flex-end"
                      direction="row"
                      spacing={theme.spacing(16)}
                      onClick={() => setIsOpened(false)}
                    >
                      <Typography fontWeight={500} variant="bodyM" color="common.white">
                        {item.title}
                      </Typography>

                      <IconButton sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </Stack>
                  </LinkChildren>
                ))}
              </Stack>
            </Box>

            <Divider
              sx={{ borderColor: 'alphaWhite.40', borderBottomWidth: theme.spacing(1), borderStyle: 'dashed' }}
            />

            <LinkChildren
              rel={data.link.rel}
              target={data.link.target}
              isExternal={data.link.isExternal}
              url={data.link.url}
            >
              <Button
                disableRipple
                sx={{ width: '100%', textAlign: 'right', padding: theme.spacing(16, 0, 22) }}
                variant="textLink"
                color="textLinkOnDark"
                onClick={() => setIsOpened(false)}
              >
                {data.link.title}
              </Button>
            </LinkChildren>
          </motion.div>
        )}
      </AnimatePresence>

      <Button
        sx={{ ml: 'auto', width: 'fit-content', borderRadius: theme.spacing(48) }}
        onClick={() => setIsOpened((open) => !open)}
        variant="large"
        color="primary"
        endIcon={<AddIcon sx={{ rotate: isOpened ? '45deg' : '0deg', transition: '.1s' }} />}
      >
        {title}
      </Button>
    </Box>
  );
};

export default FabParagraph;
